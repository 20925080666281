import { extendTheme, Theme, theme } from "@chakra-ui/react"

const bodyFont = "Inter"
const headingFont = "Work Sans"
// const monoFont = "DejaVu Mono"
const monoFont = "Roboto Mono"

//@ts-ignore
export const myTheme = {
  setColorVar: function (varname: string, value: string) {
    const [color, num] = value.split(".")

    if (num) {
      document.documentElement.style.setProperty(
        varname,
        //@ts-ignore
        this.colors[color][num]
      )
    } else {
      //@ts-ignore
      document.documentElement.style.setProperty(varname, this.colors[color])
    }
  },
  colors: {
    secondary: {
      50: "#fafbfb",
      100: "#f0f3f2",
      200: "#dce3e0",
      300: "#c4d0cb",
      400: "#b9c7c1",
      500: "#a5b7af",
      600: "#99ada4",
      main: "#99ada4",
      700: "#718d81",
      800: "#586d63",
      900: "#2d3833",
    },
    accent: {
      50: "#fafbfa",
      100: "#f1f3f0",
      200: "#dee4dc",
      300: "#c6d0c2",
      main: "#c6d0c2",
      400: "#bcc8b7",
      500: "#a8b7a2",
      600: "#9dae96",
      700: "#768e6d",
      800: "#5b6d54",
      900: "#2f382b",
    },
    primary: {
      50: "#fbfbf7",
      100: "#f4f3e6",
      main: "#f4f3e6",
      200: "#e5e3c4",
      300: "#d3cf9b",
      400: "#cbc789",
      500: "#bcb666",
      600: "#b2ab4f",
      700: "#8f8a3f",
      800: "#6e6a30",
      900: "#383619",
    },
    dim: "#5f5f5f",
  },
  fonts: {
    heading: `${headingFont},${theme.fonts.heading}`,
    body: `${bodyFont},${theme.fonts.body}`,
    mono: `${monoFont},${theme.fonts.mono}`,
  },
  fontWeights: {
    light: 300,
  },
  lineHeights: {
    base: { base: "1.5", md: "1.6" },
  },
  sizes: {
    articleWidth: "930px",
  },
  styles: {
    global: {
      ":root": {
        "--bg-color": "white"
      },
      html: {
        "overflowY": "scroll"
      },
      body: {
        fontSize: "lg",
        bg: "var(--bg-color)",
        transition: "background 350ms ease 0s"
      },
    },
  },
}

type MergedTheme = Theme & typeof myTheme
//@ts-ignore
const mergedTheme: MergedTheme = extendTheme(myTheme)

export default mergedTheme
