module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":false,"portalZIndex":40},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michal Nowotnik","description":"Hi, my name is Michael, I work as a software consultant and I run this site. Check it out to see what I'm up to.","lang":"en","short_name":"mnowotnik.com","start_url":"/","background_color":"#c6d0c2","theme_color":"#c6d0c2","display":"minimal-ui","icon":"assets/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"def32b88d70a7aa5b38bdd51e49d8412"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
